.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  cursor: pointer;
  line-height: 30px;
  position: relative;
}

.icon:after {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  transition:
          transform 0.2s,
          opacity 0.2s;
}

.icon:hover:after {
  transform: scale(2.45);
  opacity: 0.8;
}

.pulsing:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-shadow: inset 0 0 0 3px #c4b5fd;
  transition:
          transform 0.2s,
          opacity 0.2s;
  animation: pulsing 2.7s infinite;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
