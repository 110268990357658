@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./ui/_variables.scss";
@import "./ui/icons.scss";
@import "./shared/_loading.scss";

/* Container for the zoom controls */
.leaflet-control-zoom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85px;
  border: none !important;
}
.leaflet-popup-content p {
  padding: 0;
  margin: 0;
}

/* Zoom in button */
.leaflet-control-zoom-in {
  display: block;
  width: 40px !important;
  height: 40px !important;
  border: 2px solid red !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-color: rgb(209 213 219) !important;
  //background-color: #65a30d; /* Background color for the zoom in button */
  //color: #ffffff; /* Text color */
  //font-weight: bold; /* Bold text */
  //border-bottom: 1px solid #3b45a0; /* Border between zoom in and zoom out */
  //cursor: pointer; /* Cursor changes to pointer on hover */
}

/* Zoom out button */
.leaflet-control-zoom-out {
  display: block;
  width: 40px !important;
  height: 40px !important;
  border: 2px solid red !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-color: rgb(209 213 219) !important;
  //background-color: #3b45a0; /* Background color for the zoom out button */
  //color: #ffffff; /* Text color */
  //font-weight: bold; /* Bold text */
  //cursor: pointer; /* Cursor changes to pointer on hover */
}

/* Hover effect for zoom in button */
.leaflet-control-zoom-in:hover {
  //background-color: #4e840d; /* Slightly darker green on hover */
}

/* Hover effect for zoom out button */
.leaflet-control-zoom-out:hover {
  //background-color: #2b3685; /* Slightly darker blue on hover */
}


